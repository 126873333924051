<template>
  <va-card title="Create Organization">
    <va-input
      label="Name"
      v-model="organization.name"
      placeholder="Lafayette General "
      required
    >
      <va-icon slot="prepend" color="gray" name="fa fa-vcard" />
    </va-input>
    <va-input
      label="Address"
      v-model="organization.address"
      placeholder="3909 Ambassador Caffery Pkwy "
    >
      <va-icon slot="prepend" color="gray" name="fa fa-address-card" />
    </va-input>
    <va-input label="City" v-model="organization.city" placeholder="Lafayette ">
      <va-icon slot="prepend" color="gray" name="fa fa-address-card" />
    </va-input>

    <va-input
      label="Postal Code"
      v-model="organization.zip"
      placeholder="70508 "
    >
      <va-icon slot="prepend" color="gray" name="fa fa-vcard" />
    </va-input>
    <va-input
      label="Phone"
      v-model="organization.phone"
      placeholder="8662944599 "
    >
      <va-icon slot="prepend" color="gray" name="fa fa-phone" />
    </va-input>
    <va-select
      label="Country"
      @input="onCountryChange"
      v-model="organization.country"
      placeholder="i.e United States "
      :options="countries"
    />
    <va-select
      v-if="stateIsListed"
      :disabled="organization.country != 'United States'"
      label="State"
      v-model="organization.state"
      text-by="name"
      key-by="name"
      placeholder="i.e Louisiana "
      :options="states"
    >
    </va-select>
    <va-input
      v-else
      label="State"
      v-model="organization.state"
      placeholder="i.e Louisiana "
    ></va-input>

    <va-input
      class="mb-4"
      v-model="organization.comments"
      placeholder="... (Optional Field)"
      type="textarea"
      label="Comments"
    />
    <va-checkbox label="Active" v-model="organization.active" />
    <va-button class="justify-center" :disabled="!allFilledOut" @click="submit">
      Create Organization
    </va-button>
  </va-card>
</template>

<script>
import states from "../../data/states.json";
import countries from "../../data/CountriesList";

export default {
  data() {
    return {
      countries,
      states: states,
      stateIsListed: true,
      organization: {
        name: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
        phone: "",
        comments: "",
        active: true,
      },
    };
  },
  created() {
    this.onCountryChange();
    // this.$router.push({name: 'edit', params: {uid: 'jZaXHH42lxnYUMdlHElK'}});
  },
  methods: {
    onCountryChange() {
      this.stateIsListed = this.organization.country == "United States";
      this.organization.state = "";
    },
    submit() {
      this.$db
        .collection("organizations")
        .add({
          author: this.$store.state.app.user.uid,
          creationDate: this.$firebase.firestore.Timestamp.now(),
          name: this.organization.name,
          address: this.organization.address,
          city: this.organization.city,
          state: this.organization.state.length > 3 ? this.organization.state : this.organization.state.name,
          zip: this.organization.zip,
          country: this.organization.country,
          phone: this.organization.phone,
          comments: this.organization.comments,
          active: this.organization.active,
        })
        .then((snapshot) => {
          this.$router.push({ name: "edit", params: { uid: snapshot.id } });
        });
    },
  },
  computed: {
    allFilledOut() {
      const requiredFields = [
        "name",
        "address",
        "city",
        "state",
        "zip",
        "country",
        "phone",
      ];

      for (let i = 0; i < requiredFields.length; i++) {
        const field = this.organization[requiredFields[i]];
        if (field.length === 0) {
          return false;
        }
      }
      return true;
    },
  },
};
</script>

<style></style>
